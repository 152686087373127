html {
  height: 100%;
}
body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  text-align: center;
  margin: 0px;
  padding: 0px;
  background-color: black;
}

.header {
  margin: auto;
  margin-top: 0px;
  margin-bottom: 50px;
  padding-top: 20px;
  width: 70%;
  height: 70px;
  border-bottom: 1px solid white;
  position: relative;
}

.yobets-logo {
  position: absolute;
  left: 0px;
  bottom: -13px;

  height: 70px;
}

.connect-wallet-button {
  position: absolute;
  bottom: 5px;
  right: 0px;
  border-radius: 10px;
  width: 150px;
  height: 40px;
  font-size: 15px;
  font-weight: bold;
}

.GameView {
  margin-top: 200px;
  height: 350px;
  width: 900px;
  margin: auto;
  background-color: white;
  display: flex;
  flex-direction: row;
}

.game-config-div {
  background-color: #161616;
  width: 300px;
}

.rocket-ride-logo {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 20px;
}

.input-field {
  margin: auto;
  margin-bottom: 20px;
  width: 250px;
  height: 50px;
  background-color: #1F1F1F;
  position: relative;
}
.input-field-text {
  height: 70%;
  width: 70%;

  position: absolute;
  left: 10px;
  bottom: 0px;

  background-color:#1F1F1F;
  color: white;
  border: 0px;
  font-size: 15px;
  font-weight: bold;
  z-index: 0;
}
.input-field-header {
  z-index: 3;
  position: absolute;
  top: -10px;
  left: 5px;
  color: #474747;
  font-size: 12px;
  font-weight: bold;
}
.input-field-buttons {
  position: absolute;
  right: 10px;
  top: 10px;
}
.input-field-button {
  background-color: #1F1F1F;
  color: #696969;
  border: 0px; 
  font-size: 13px;
  font-weight: bold;
}

.buyin-button {
  color: white;
  background-color: #06BA44;
  border: 0px;
  width: 250px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
}

.right-sideborder {
  width: 700px;
  height: 300px;
}

.crash-view {
  width: 600px;
  height: 300px;
  position: relative;
}
.multiplierText {
  position: absolute;
  top: 50px;
  left: 210px;
  z-index: 1;
  color: white;
  font-size: 42px;
  font-weight: bold;
  text-align: center;
}

.bottom-border {
  position: absolute;
  bottom: 0px;
  left: 0px;
  
  width: 100%;
  height: 10px;

  background-color: #2EB8D7;
}

.game-header {
  background-color: #1F1F1F;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  color: white;
  font-weight: bold;
}
.game-header > * {
  margin-right: 20px;
  margin-left: 20px;
}

.deposit-effect {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  background-color: #1F1F1F;
  opacity: 0.7;
  z-index: 5;
}

.deposit-window {
  margin: auto;
  position: absolute;
  top: 10%;
  left: 35%;

  background-color: black;
  width: 400px;
  height: 400px;
  z-index: 6;
}

.deposit-header {
  color: white;
}
.deposit-input {
  background-color: #1F1F1F;
  color: white;
  border: 0px;
  width: 180px;
  height: 40px;

  font-weight: bold;
}
.commit-button {
  background-color: #2EB8D7;
  color: white;
  border-radius: 5px;
  border: 0px;
  width: 150px;
  height: 50px;
  font-size: 15px;
  font-weight: bold;
}
.centered-deposit {
  padding: 80px;
}
.close-deposit-window {
  background-color: black;
  border: 0px;
  font-size: 20px;
  position:absolute;
  top: 5px;
  left: 5px;
  color: #696969;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  outline: none !important;
}